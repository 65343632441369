const SOFTWALL_PREMIUM_USER_DETECTED_KEY =
  "BT_AM_PREMIUM_ADBLOCK_USER_DETECTED";
const SOFTWALL_PREMIUM_USER_DETECTION_DATE_KEY =
  "BT_AM_PREMIUM_ADBLOCK_USER_DETECTION_DATE";

const ONE_DAY = 1000 * 60 * 60 * 24;

const onInvalidOrInexistentDetectionData = () => {
  localStorage.removeItem(SOFTWALL_PREMIUM_USER_DETECTED_KEY);
  localStorage.removeItem(SOFTWALL_PREMIUM_USER_DETECTION_DATE_KEY);
  throw new Error("invalid or inexistent premium detection data");
};

export const getPremiumDetectionData = () => {
  const detectionUTCString = localStorage.getItem(
    SOFTWALL_PREMIUM_USER_DETECTION_DATE_KEY
  );

  const detectionData = localStorage.getItem(
    SOFTWALL_PREMIUM_USER_DETECTED_KEY
  );

  try {
    if (!detectionUTCString || !detectionData) {
      onInvalidOrInexistentDetectionData();
    }

    const detectionDate = new Date(detectionUTCString as string);
    if (Date.now() - detectionDate.getTime() > ONE_DAY) {
      onInvalidOrInexistentDetectionData();
    }

    const isPremium = JSON.parse(detectionData as string);

    return { isPremium };
  } catch (e) {
    return null;
  }
};

export const savePremiumDetection = () => {
  localStorage.setItem(SOFTWALL_PREMIUM_USER_DETECTED_KEY, "true");
  localStorage.setItem(
    SOFTWALL_PREMIUM_USER_DETECTION_DATE_KEY,
    new Date().toUTCString()
  );
};
