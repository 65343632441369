import { RLinkAPIEventManager } from "./EventManager";
import { AutoModeAPI } from "./modes/AutoModeAPI";
import { EventHandler, IRLinkAPI } from "./types";
import { WaitModeAPI } from "./modes/WaitModeAPI";

const DEFAULT_API_READY_EVENT_INTERVAL = 200;
const DEFAULT_API_PREVENT_WAIT_TIME = 50;

export type RLinkAPIOptions = {
  apiReadyEventInterval?: number;
  mode?: "wait" | "auto";
  apiPreventWaitTime?: number;
};

export class RLinkAPI {
  /**
   * The event manager to handle the RLink API events.
   */
  private eventManager: RLinkAPIEventManager;
  constructor(
    render: () => void,
    options: RLinkAPIOptions = {},
    eventListener: EventHandler = window
  ) {
    let api: IRLinkAPI;
    const {
      mode = "wait",
      apiReadyEventInterval = DEFAULT_API_READY_EVENT_INTERVAL,
      apiPreventWaitTime = DEFAULT_API_PREVENT_WAIT_TIME,
    } = options;
    if (mode === "auto") {
      api = new AutoModeAPI(render, apiPreventWaitTime);
    } else {
      api = new WaitModeAPI(render);
    }
    this.eventManager = new RLinkAPIEventManager(
      api,
      eventListener,
      apiReadyEventInterval
    );
  }

  /**
   * Exposes the RLink API by starting the event manager.
   */
  expose() {
    this.eventManager.start();
  }
}
