import { IRLinkAPI } from "../types";

export class AutoModeAPI implements IRLinkAPI {
  private shouldRender = true;
  constructor(private render: () => void, private apiPreventWaitTime) {}

  start() {
    this.renderIfNotPrevented();
  }
  /**
   * Return the payload for the ready event.
   */
  getAPIPayload() {
    return {
      prevent: () => {
        this.preventRender();
      },
      render: () => {
        this.render();
      },
    };
  }

  private renderIfNotPrevented() {
    setTimeout(() => {
      if (!this.shouldRender) return;
      this.render();
    }, this.apiPreventWaitTime);
  }

  private preventRender() {
    this.shouldRender = false;
  }
}
