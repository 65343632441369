import { restoreServerSideUserAttr } from "./serverSideUserAttr";
import { setServerSideUserState } from "../utils/serverSideAttr";

export const SOFTWALL_RENDERED_KEY = "BT_AM_SOFTWALL_RENDERED";

export const hasRenderData = () =>
  sessionStorage.getItem(SOFTWALL_RENDERED_KEY) === "true";

export async function getRenderData(): Promise<boolean> {
  // if window.__bt_intrnl.recoveryUserAttr defined it means that
  // bt_tag or bt_am already tried to restore server side user attribution in current page view,
  // so the render data could be getting from the window object;
  if (window.__bt_intrnl.recoveryUserAttr) {
    return window.__bt_intrnl.recoveryUserAttr.mwRendered;
  }
  const isRendered = Boolean(localStorage.getItem(SOFTWALL_RENDERED_KEY));
  if (!isRendered) {
    // restore render and recovery state from server side in case if user clear the browser data
    return restoreServerSideUserAttr()
      .then(() => Boolean(localStorage.getItem(SOFTWALL_RENDERED_KEY)))
      .catch(() => false); // if restoring failed return false
  }
  return isRendered;
}

export const getLastRenderDate = () => {
  const lastRenderDateStr = localStorage.getItem(SOFTWALL_RENDERED_KEY);
  return lastRenderDateStr ? new Date(lastRenderDateStr) : undefined;
};

export const saveRenderData = () => {
  saveRender();
  setServerSideUserState();
};

const saveRender = () => {
  sessionStorage.setItem(SOFTWALL_RENDERED_KEY, "true");
  localStorage.setItem(SOFTWALL_RENDERED_KEY, new Date().toUTCString());
};

export const restoreRenderDate = (renderDate: Date) => {
  localStorage.setItem(
    SOFTWALL_RENDERED_KEY,
    new Date(renderDate).toUTCString()
  );
};

export const deleteRenderData = () =>
  sessionStorage.removeItem(SOFTWALL_RENDERED_KEY);
