import {
  orgID,
  websiteID,
  traceID,
  sessionID,
  apiDomain,
  getSettings,
  RenderMode,
} from "../../settings";
import { AdBlockerType, PageViewEventType } from "../types";

type SoftwallBaseEvent = {
  recoveryType?: "AA" | "AB";
  detectedExtension: AdBlockerType;
};

export type SoftwallDismissEvent = {
  event: "dismiss";
  element: "x-button" | "continue-to-site" | "refresh-button";
  tab?: AdBlockerType;
} & SoftwallBaseEvent;

type SoftwallRenderedEvent = {
  event: "rendered";
  renderMode: RenderMode;
} & SoftwallBaseEvent;

type SoftwallRecoveredEvent = {
  event: "recovered";
} & SoftwallBaseEvent;

type SoftwallFlattrEvent = {
  event: "flattr";
} & SoftwallBaseEvent;

type SoftwallNaviageAvayEvent = {
  event: "navigate-away";
  renderMode: RenderMode;
} & SoftwallBaseEvent;

type SoftwallPrimaryCtaRedirectEvent = {
  event: "primary-cta-redirect";
} & SoftwallBaseEvent;

type SoftwallPageViewEvent = {
  event: PageViewEventType;
  consentMessageShown?: boolean;
} & SoftwallBaseEvent;

export type SoftwallEvent =
  | SoftwallDismissEvent
  | SoftwallRenderedEvent
  | SoftwallRecoveredEvent
  | SoftwallFlattrEvent
  | SoftwallPageViewEvent
  | SoftwallNaviageAvayEvent
  | SoftwallPrimaryCtaRedirectEvent;

type MWEventRequest = {
  orgID: number;
  traceID: string;
  websiteID: number;
  event: string;
  sessionID?: string;
  meta?: unknown;
  element?: string;
  recoveryType?: string;
  detectedExtension: string;
  scriptVersion: string;
  scriptLoadingMode: string;
};

export async function sendEvent(event: SoftwallEvent) {
  const settings = await getSettings();
  callEventsAPI({
    traceID,
    sessionID,
    orgID: Number(orgID),
    websiteID: Number(websiteID),
    scriptVersion: __SCRIPT_VERSION__,
    scriptLoadingMode: window.__bt_rlink_loaded_from_tag
      ? "inject_from_tag_script"
      : "standalone",
    meta: {
      pageURL: window.location.href,
      ["abtest-presentation-version"]: settings.view,
      // temporarily solution to track consent data
      consentData: window.__bt_intrnl.consentData,
    },
    ...event,
  });
}

function callEventsAPI(request: MWEventRequest) {
  return fetch(`https://${apiDomain}/events/mw`, {
    method: "POST",
    body: JSON.stringify(request),
    headers: {
      "Content-Type": "application/json",
    },
    mode: "no-cors",
    keepalive: true,
  });
}
