import {
  orgID,
  websiteID,
  traceID,
  sessionID,
  apiDomain,
} from "../../settings";
import { QUERY_PARAMS } from "./queryParams";

type LogParams = {
  severity: number;
};

const traceIDQueryParameter = "tid";
const btTagVersionQueryParameter = "cv";
const sessionIDQueryParameter = "sid";

/*
severity mapping:
1. "debug" -> 0
2. "info" -> 1
3. "notice" -> 2
4. "warning" -> 3
5. "error" -> 4
6. "critical" -> 5
7. "alert" -> 6
8. "emergency" -> 7
*/
export async function log(
  event: string,
  meta: Record<string, unknown> = {},
  params: LogParams = { severity: 1 }
) {
  if (QUERY_PARAMS.preview) {
    return;
  }

  const query = {
    [traceIDQueryParameter]: traceID,
    [btTagVersionQueryParameter]: window.__bt_tag_d?.version || "",
    [sessionIDQueryParameter]: sessionID,
    upapi: String(true),
  };

  const body = {
    event,
    meta,
    orgID,
    severity: params.severity,
    websiteID: websiteID,
  };

  const search = new URLSearchParams(query);
  const url = new URL(`https://${apiDomain}/log?` + search);

  return fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    keepalive: true,
  });
}

/*
  These methods are used to log only a certain percentage of logs
  Usually only 1% by default to avoid overwhelmind our storage
  Copied from up-browser repo commonUtils
 */

// Returns a random integer between zero on the `max` value (for example: if max=100, the range is [0-99])
export function getRandomInt(max: number) {
  return Math.floor(Math.random() * Math.floor(max));
}

// Returns true a given percentage (0-100) of the time
// 1% by default
export function shouldLogByProbability(percentage: number = 1) {
  return getRandomInt(100) < percentage;
}
