import { apiDomain, LocalSettings, websiteID } from "../../settings";
import { getPremiumDetectionData } from "../data";
import { verifySignature } from "./verifySignature";
import { BT_ENV } from "./queryParams";

class PremiumABUserDetector {
  private singletonDetection?: Promise<boolean | void>;

  constructor(settings: LocalSettings) {
    const { premium_bypass_mode_enabled } = settings;
    if (!premium_bypass_mode_enabled) {
      this.singletonDetection = Promise.resolve();
      return;
    }

    this.detectPremiumABUser();
  }

  public async detectPremiumABUser() {
    if (this.singletonDetection) {
      return this.singletonDetection;
    }

    this.singletonDetection = new Promise<boolean>((resolve) => {
      const previousDetection = getPremiumDetectionData();

      if (previousDetection) {
        resolve(previousDetection.isPremium);
        return;
      }

      fetch(`https://${apiDomain}/mw/sign_pbm?w=${websiteID}&bt_env=${BT_ENV}`)
        .then((r) => r.json())
        .then((detail) => {
          const timeout = setTimeout(() => {
            resolve(false);
            return;
          }, 1000);

          document.addEventListener("flattr-payload", ((
            event: CustomEvent<{ payload: string }>
          ) => {
            clearTimeout(timeout);
            const [encodedData, signature] = event.detail.payload.split(".");

            verifySignature({
              encodedData,
              signature,
            })
              .then((isValid) => {
                if (!isValid) resolve(false);

                const data: { isPaying: boolean } = JSON.parse(
                  atob(encodedData)
                );
                resolve(data.isPaying);
              })
              .catch(() => {
                resolve(false);
              });
          }) as EventListener);

          const event = new CustomEvent("flattr-request-payload", {
            detail,
          });
          document.dispatchEvent(event);
        })
        .catch(() => {
          resolve(false);
        });
    });

    return this.singletonDetection;
  }
}

let premiumAbUserDetector: PremiumABUserDetector | null = null;

export async function detectPremiumABUser(settings: LocalSettings) {
  if (!premiumAbUserDetector) {
    premiumAbUserDetector = new PremiumABUserDetector(settings);
  }
  return premiumAbUserDetector.detectPremiumABUser();
}
