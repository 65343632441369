import {
  AADetectionResults,
  CountryCodeMode,
  GoogleTag,
  Prebid,
  SoftwallRecoveredType,
  ConsentData,
} from "./softwall/types";
import { BT_ENV, QUERY_PARAMS } from "./softwall/utils/queryParams";
import tinycolor from "tinycolor2";
import { type CSSProperties } from "preact/compat";

export type RenderMode = "always" | "api";

export type APIMode = "wait" | "auto";

export type InstructionFields = {
  title: string;
  steps: string[];
};

export type i18nFields = {
  call_to_action?: string;
  landing_text?: string;
  name?: string;
  title?: string;
  custom_cta?: {
    primary_text?: string;
    secondary_text?: string;
  };
  default_translations?: {
    title: string;
    landing_text: string;
    refresh: string;
    allow: string;
    continue: string;
    continue_without_supporting: string;
    or: string;
    instructions: Record<string, InstructionFields>;
  };
};

export type i18nResource = Record<string, i18nFields>;

export type Settings = {
  enabled: boolean;
  logo: string;
  view?: "modal" | "footer" | "custom-cta-modal";
  single_click_enabled: boolean;
  hard_message_wall_mode_enabled: boolean;
  adaptive_hard_message_wall_mode_enabled: boolean;
  page_views_to_render?: string;
  premium_bypass_mode_enabled?: boolean;
  cta_button_color: string;
  font_type: string;
  allow_render_to_aa_users?: boolean;
  button_style: CSSProperties;
  render_interval_days?: string;
  country_code_mode?: CountryCodeMode;
  country_code_list?: string[];
  script_loading_mode: "inject_from_tag_script" | "standalone";
  render_mode: RenderMode;
  api_mode: APIMode;
  custom_css?: string;
  custom_cta_modal?: {
    primary_cta_url: string;
    secondary_cta_url: string;
  };
  allowlisted_urls?: string[];
  i18n?: i18nResource;
};

declare global {
  interface Window {
    __bt_tag_d?: {
      orgID?: string;
      version: string;
      siteInfo?: {
        websiteID?: string;
      };
    };
    __bt_intrnl: {
      traceID: string;
      aaDetectionResults: AADetectionResults;
      recoveryUserAttr: {
        recoveryType: SoftwallRecoveredType;
        mwRendered: boolean;
      };
      rlPVSent: boolean;
      country: string | undefined;
      timings: {
        shouldLog: boolean;
        navigationStartTimestamp: number;
        tagScriptLoadTimestamp: number;
      };
      consentData: ConsentData;
    };
    __bt_tag_am: {
      settings: Settings;
    };
    googletag: GoogleTag;
    _pbjsGlobals: PrebidKey[];

    [key: PrebidKey]: Prebid;

    // Function to load the recovery script
    __bt_recovery?: () => void;
    // Flag to indicate if the rlink script was already loaded in this page view
    __bt_rlink_already_invoked: boolean;
    // Flag to indicate if the rlink script was loaded from the tag script
    __bt_rlink_loaded_from_tag: boolean;
    // Mock config to run the standalone rlink script locally
    __bt_rlink_test?: {
      hostname?: string;
      params?: {
        o?: string;
      };
    };
  }

  // Add Brave as type to Navigator object, it's not included by default
  interface Navigator {
    brave: {
      isBrave(): boolean;
    };
  }
}

type PrebidKey = string;
export const apiDomain = getApiDomain();
export const assetsLocation = getAssetsLocation();
export let websiteID = window.__bt_tag_d?.siteInfo?.websiteID;
export const orgID = window.__bt_tag_d?.orgID || getOrgId();
const hostname = getHostName();
export const traceID = window.__bt_intrnl?.traceID || getTraceID();
export const sessionID = getSessionId();

function getSessionId() {
  let sessionId = sessionStorage.getItem("BT_sid");
  if (!sessionId) {
    sessionId = randomRange(8, 10);
    sessionStorage.setItem("BT_sid", sessionId);
  }
  return sessionId;
}

function getOrgId() {
  // script URL going to return initial source of injected script
  // on using redirect to new URL on dev testing pay attention that this value will not change to the redirect source
  if (
    (import.meta.env.MODE === "development" ||
      import.meta.env.MODE === "test") &&
    window.__bt_rlink_test?.params?.o
  ) {
    return window.__bt_rlink_test.params.o;
  }
  const scriptURL = new URL(import.meta.url);
  const queryParams = new URLSearchParams(scriptURL.search);
  const oParam = queryParams.get("o");
  if (!oParam) throw new Error("o query param not found");

  // Cache websiteID in window.__bt_tag_d because the other chunks do not have access to the query param
  if (!window.__bt_tag_d) window.__bt_tag_d = {};
  window.__bt_tag_d.orgID = oParam;

  return oParam;
}

function getApiDomain() {
  const apiDomain = import.meta.env.VITE_API_DOMAIN;
  return verifyEnvSet("API_DOMAIN", apiDomain);
}

function getAssetsLocation() {
  const assetsLocation = import.meta.env.VITE_ASSETS_LOCATION;
  return verifyEnvSet("ASSETS_LOCATION", assetsLocation);
}

function verifyEnvSet(key: string, value: string | null | undefined): string {
  if (value === undefined || value === null || value === "") {
    throw new Error("missing env var for " + key);
  }
  return value;
}

function getHostName() {
  if (
    (import.meta.env.MODE === "development" ||
      import.meta.env.MODE === "test") &&
    window.__bt_rlink_test?.hostname
  ) {
    return window.__bt_rlink_test?.hostname;
  }
  let hostname = window.location.hostname;

  if (hostname.startsWith("www.")) {
    hostname = hostname.replace("www.", "");
  }

  return hostname;
}

function randomRange(min: number, max: number) {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  if (
    typeof min !== "number" ||
    typeof max !== "number" ||
    min <= 0 ||
    max <= 0 ||
    min > max
  ) {
    throw new TypeError();
  }
  const n = Math.floor(Math.random() * (max + 1 - min)) + min;
  let s = "";
  for (let i = 0; i < n; i++) {
    s += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return s;
}

function getTraceID(): string {
  // since TraceID could be generated in bt_tag and mw injected to the page
  // we have to ensure that new value set in variable available for both scripts
  window.__bt_intrnl = window.__bt_intrnl || {};
  window.__bt_intrnl.traceID = randomRange(8, 10);

  return window.__bt_intrnl.traceID;
}

function getButtonStyle(ctaButtonCustomColor: string) {
  const backgroundColor = tinycolor(ctaButtonCustomColor);
  if (!backgroundColor.isValid()) {
    return {};
  }
  const color = backgroundColor.isLight() ? "black" : "white";

  return {
    backgroundColor: backgroundColor.toString("hex"),
    borderColor: backgroundColor.toString("hex"),
    color,
  };
}
async function loadSetting() {
  window.__bt_tag_am = window.__bt_tag_am || {};
  if (!orgID || !hostname) throw new Error("orgID or hostname not found");
  const websiteConfigUrl = new URL(`https://${apiDomain}/websiteconfig`);
  websiteConfigUrl.searchParams.set("o", orgID);
  websiteConfigUrl.searchParams.set("w", hostname);
  websiteConfigUrl.searchParams.set("bt_env", BT_ENV);

  const response = await fetch(websiteConfigUrl.toString());

  if (!response.ok) {
    throw new Error();
  }
  const settings = await response.json();
  window.__bt_tag_am.settings = settings.messageWall;
  const w = settings.tagData?.w;

  // Cache websiteID in window.__bt_tag_d.siteInfo
  if (!window.__bt_tag_d) window.__bt_tag_d = {};
  if (!window.__bt_tag_d.siteInfo) window.__bt_tag_d.siteInfo = {};
  window.__bt_tag_d.siteInfo.websiteID = w;
  websiteID = w;

  console.log("website id find: ", websiteID);

  return window.__bt_tag_am.settings;
}

export interface LocalSettings
  extends Omit<Omit<Settings, "page_views_to_render">, "render_interval_days"> {
  page_views_to_render: number;
  render_interval_days: number;
}

let settingsDeferred: Promise<Settings> | null = null;
const resolveSettings = async () => {
  if (window.__bt_tag_am?.settings) {
    return window.__bt_tag_am?.settings;
  }
  if (settingsDeferred) {
    return settingsDeferred;
  }
  settingsDeferred = loadSetting();
  return settingsDeferred;
};

export const getSettings = async (): Promise<LocalSettings> => {
  const baseSettings = await resolveSettings();

  const view = baseSettings.view || "modal";
  const page_views_to_render = Number(baseSettings.page_views_to_render);
  const render_interval_days = Number(baseSettings.render_interval_days);
  const allow_render_to_aa_users = Boolean(
    baseSettings.allow_render_to_aa_users
  );

  return {
    ...baseSettings,
    font_type: baseSettings.font_type
      ? `${baseSettings.font_type}, sans-serif`
      : "",
    view,
    page_views_to_render,
    render_interval_days,
    allow_render_to_aa_users,
    button_style: baseSettings.cta_button_color
      ? getButtonStyle(baseSettings.cta_button_color)
      : {},
    i18n: baseSettings.i18n,
  };
};

export const loadRecoveryScript = ({ script_loading_mode }: LocalSettings) => {
  // the recovery script should be loaded from the bt_am only for SoftWall
  // and the script was loaded is on inject from tag mode
  // for other cases it will be loaded from the bt_tag

  if (QUERY_PARAMS.preview) return;
  if (script_loading_mode !== "inject_from_tag_script") return;
  if (!window.__bt_rlink_loaded_from_tag) return;

  window.__bt_recovery?.();
};
