import { AADetectionResults } from "../types";
import { setAADetectionData } from "../data/abDetection";

const ADBLOCK_BAIT_PIXEL_URL = "https://ad-delivery.net/px.gif?ch=2";
const OLD_ADBLOCK_BAIT_PIXEL_URL =
  "https://ad.doubleclick.net/favicon.ico?ad=300x250&ad_box_=1&adnet=1&showad=1&size=250x250";
const AA_BAIT_PIXEL_URL =
  "https://ad-delivery.net/px.gif?ch=1&e=" + Math.random();

let detectAADeferred: Promise<AADetectionResults> | null = null;
async function detectAA() {
  return Promise.all([detectAdBlock(), detectAcceptableAds()]).then(
    (aaResults) => {
      const currentResults: AADetectionResults = {
        ab: aaResults[0],
        acceptable: aaResults[1],
      };

      // set actual result to global variable and local storage for consistency;
      setAADetectionData(currentResults);
      window.__bt_intrnl.aaDetectionResults = currentResults;

      return window.__bt_intrnl.aaDetectionResults;
    }
  );
}
export async function getAADetectionResult(): Promise<AADetectionResults> {
  // getting window.__bt_intrnl.aaDetectionResults means that
  // bt_tag or bt_am already did detection in current page view,
  // so has no sense to did it again;
  // using window variable covers the situation where the user don't consent to storage
  if (window.__bt_intrnl.aaDetectionResults) {
    return window.__bt_intrnl.aaDetectionResults;
  }

  if (detectAADeferred) {
    return detectAADeferred;
  }

  detectAADeferred = detectAA();
  return detectAADeferred;
}

async function detectAdBlock(): Promise<boolean> {
  const [isPixelBlocked, isOldPixelBlocked] = await Promise.all([
    bait(ADBLOCK_BAIT_PIXEL_URL),
    bait(OLD_ADBLOCK_BAIT_PIXEL_URL),
  ]);
  const isDivRemoved = injectDiv();
  const isAdBlockDetected = isPixelBlocked
    ? isDivRemoved || isOldPixelBlocked
    : isDivRemoved && isOldPixelBlocked;
  return isAdBlockDetected || false;
}

async function detectAcceptableAds(
  url: string = AA_BAIT_PIXEL_URL
): Promise<boolean> {
  const isPixelBlocked = await bait(url);
  return !isPixelBlocked;
}

async function bait(url: string) {
  // we first try to bait with the traditional pixel method, injecting an img tag with the bait url as the src attribute.
  // ublock origin is not detected using this traditional approach, because the extension will sometimes perform redirections in the requests to
  // deliver an empty response with a 200 (ater a 307 redirection) to make ad blocking detection more obfuscated.

  const isBlocked = await injectHTMLElement(url);

  if (isBlocked) {
    return true;
  }

  try {
    const r = await fetch(url);
    // empty image ubo uses to replace original resource with
    if (r.redirected && r.url === "data:text/plain;base64,Cg==") {
      throw new Error("Extension driven redirect detected");
    }

    return false;
  } catch (e) {
    // if request failed client-side, which is also something ubo does,
    // we assume adblock presence: the request was blocked by client-side code.
    // the promise doesn't reject even if a 4xx or 5xx code is returned by the server.
    return true;
  }
}

function injectDiv() {
  const baitElement = document.createElement("div");
  baitElement.innerHTML = "&nbsp;";
  baitElement.className =
    "ads ad adsbox doubleclick ad-placement carbon-ads adglare";
  baitElement.setAttribute("id", "bt-bait-element");
  baitElement.style.cssText =
    "width: 1px !important; height: 1px !important; position: absolute !important; left: -5000px !important; top: -5000px !important;";
  try {
    (window.document.body || window.document.documentElement).appendChild(
      baitElement
    );
    const elementOnPage = document.getElementById(
      "bt-bait-element"
    ) as HTMLElement;
    // adBlock blocks div from rendering, so the offset height becomes zero
    if (
      (elementOnPage as HTMLElement).offsetHeight === 0 ||
      elementOnPage.clientHeight === 0
    ) {
      removeBaitElement(baitElement);
      return true;
    }
    if (window.getComputedStyle !== undefined) {
      const baitElementStyles = window.getComputedStyle(elementOnPage, null);
      // adBlock lets div to render, but hides it
      if (
        baitElementStyles &&
        (baitElementStyles.getPropertyValue("display") === "none" ||
          baitElementStyles.getPropertyValue("visibility") === "hidden")
      ) {
        removeBaitElement(baitElement);
        return true;
      }
    }
  } catch (e) {
    removeBaitElement(baitElement);
    return null;
  }
  removeBaitElement(baitElement);
  return false;
}

function injectHTMLElement(url: string, tagName: "img" | "script" = "img") {
  return new Promise<boolean>((resolve) => {
    // attempt to access a forbidden URL
    const baitElement = document.createElement(tagName);
    baitElement.style.setProperty("display", "none", "important");
    baitElement.style.setProperty("width", "1px", "important");
    baitElement.style.setProperty("height", "1px", "important");

    let executed = false;
    function callback(wasBlocked: boolean) {
      if (executed) {
        return;
      }
      executed = true;
      resolve(wasBlocked);
    }

    baitElement.addEventListener("error", function () {
      callback(true);
    });

    baitElement.addEventListener("load", function () {
      callback(false);
    });

    baitElement.src = url;
    (window.document.body || window.document.documentElement).appendChild(
      baitElement
    );
  });
}

function removeBaitElement(baitElement: HTMLElement) {
  (window.document.body || window.document.documentElement).removeChild(
    baitElement
  );
}
