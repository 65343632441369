import { QUERY_PARAMS } from "../utils/queryParams";
import { apiDomain } from "../../settings";
import { restoreRenderDate } from "./render";
import { saveRecovery } from "./recovery";

export async function restoreServerSideUserAttr() {
  if (QUERY_PARAMS.ignore_cache) {
    // option to ignore recovery state cached at the back-end side
    return;
  }
  const bt_env = QUERY_PARAMS.bt_env || "prod";

  return fetch(`https://${apiDomain}/mw/state?bt_env=${bt_env}`)
    .then((r) => r.json())
    .then((userAttr) => {
      const { renderDate, recoveryType } = userAttr || {};
      window.__bt_intrnl.recoveryUserAttr = window.__bt_intrnl
        .recoveryUserAttr || {
        mwRendered: false,
        recoveryType: undefined,
      };
      if (renderDate) {
        window.__bt_intrnl.recoveryUserAttr.mwRendered = true;
        restoreRenderDate(renderDate);
      }
      if (recoveryType) {
        window.__bt_intrnl.recoveryUserAttr.recoveryType = recoveryType;
        saveRecovery(recoveryType);
      }
    })
    .catch(() => null); // to avoid fail on Promise.all
}
