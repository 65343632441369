import { hasRecoveryData, saveRecoveryData } from "../data";
import { AdBlockerType, SoftwallRecoveredType } from "../types";
import { appendKVPToAdRequest, sendEvent } from "../utils";

export const onRecover = (
  recovered: SoftwallRecoveredType,
  detectedExtension: AdBlockerType
) => {
  if (recovered === "AB") {
    appendKVPToAdRequest();
  }

  if (!hasRecoveryData()) {
    saveRecoveryData(recovered);

    sendEvent({
      event: "recovered",
      recoveryType: recovered,
      detectedExtension,
    });
  }
};
