// AdBlock exposed asset
import { AdBlockerType } from "../types";
import { assetsLocation } from "../../settings";

const AB_ASSET =
  "chrome-extension://gighmmpiobklfepjocnamgkkbiglidom/icons/ab-19.png";
const ADBLOCK_BAIT_PIXEL_URL = "https://ad-delivery.net/px.gif?ch=2";
const OLD_ADBLOCK_BAIT_PIXEL_URL =
  "https://ad.doubleclick.net/favicon.ico?ad=300x250&ad_box_=1&adnet=1&showad=1&size=250x250";
const AA_BAIT_PIXEL_URL =
  "https://ad-delivery.net/px.gif?ch=1&e=" + Math.random();

async function detectAdBlock() {
  try {
    // Fetch an exposed asset from the AdBlock extension
    // If the request is successful it means that the user has it installed
    const response = await fetch(new Request(AB_ASSET));
    if (!response.ok) {
      return;
    }
    return "ab" as const;
  } catch (e) {
    return;
  }
}

async function detectOperaBrowser() {
  try {
    const userAgent = navigator.userAgent;
    if (
      userAgent.includes("OPR/") ||
      userAgent.includes("Opera/") ||
      window.opr !== undefined
    ) {
      return "opr" as const;
    }
  } catch (e) {
    return;
  }
}

async function detectUBlock() {
  try {
    const response = await fetch(
      new Request(`https://${assetsLocation}/webfonts43j533.js`)
    );
    if (!response.ok && response.status === 404) {
      return null;
    }
    return;
  } catch (e) {
    return "ublock" as const;
  }
}

async function detectUBlockOrigin() {
  for (const url of [
    ADBLOCK_BAIT_PIXEL_URL,
    OLD_ADBLOCK_BAIT_PIXEL_URL,
    AA_BAIT_PIXEL_URL,
  ]) {
    try {
      const response = await fetch(url);
      // empty image ubo uses to replace original resource with
      if (
        response.redirected &&
        response.url === "data:text/plain;base64,Cg=="
      ) {
        return "ublockorigin" as const;
      }
    } catch (e) {
      continue;
    }
  }
}

async function detectBrave() {
  try {
    if (
      navigator.brave !== undefined &&
      navigator.brave.isBrave.name === "isBrave"
    ) {
      return "brave" as const;
    }
  } catch (e) {
    return;
  }
}

export async function detectAdBlocker(): Promise<AdBlockerType> {
  const [ab, opr, ublock, brave, ublockOrigin] = await Promise.all([
    detectAdBlock(),
    detectOperaBrowser(),
    detectUBlock(),
    detectBrave(),
    detectUBlockOrigin(),
  ]);
  return (ab ||
    opr ||
    ublock ||
    brave ||
    ublockOrigin ||
    "abp") as AdBlockerType;
}
