import { AdBlockerType } from "../types";
import { LocalSettings } from "../../settings";
import { sendEvent } from "../utils";
import { hasBounceData, saveBounceData } from "../data";

export const onBounce = (
  detectedExtension: AdBlockerType,
  settings: LocalSettings
) => {
  if (!hasBounceData()) {
    saveBounceData();
    sendEvent({
      event: "navigate-away",
      detectedExtension: detectedExtension,
      renderMode: settings.render_mode,
    });
  }
};
