import { getAssetURL } from "../assets";
import { log } from "./logs";

let iframe = null;
const STORAGE_URL = getAssetURL("bt-rlink-storage.html");
const IFRAME_ID = "rlink-storage";

export const initIframe = async () => {
  // check iframe exist by id to prevent double creation;
  iframe = document.getElementById(IFRAME_ID);
  if (iframe) {
    return iframe;
  }

  try {
    iframe = await initializeIframe(STORAGE_URL);
  } catch (error) {
    throw new Error(`Failed to initialize iframe: ${error}`);
  }
};
const initializeIframe = (storageURL) => {
  return new Promise((resolve, reject) => {
    // Check if iframe already exists
    if (iframe) {
      resolve(iframe);
      return;
    }

    iframe = document.createElement("iframe");
    iframe.src = storageURL;
    iframe.id = IFRAME_ID;
    iframe.style.display = "none";
    document.body.appendChild(iframe);

    iframe.onload = () => {
      resolve(iframe);
    };

    iframe.onerror = () => {
      reject("Failed to load iframe");
    };
  });
};

export const postMessageToIframe = (message) =>
  new Promise((resolve, reject) => {
    const customMessage = { ...message, trustedRLEvent: true };
    if (!customMessage || !customMessage.action || !customMessage.key) {
      reject("Invalid message format");
      return;
    }
    function handleResponse(event) {
      if (event.origin === new URL(STORAGE_URL).origin) {
        if (event.data.status === "success") {
          resolve(event.data.value);
        } else {
          reject("Failed on handle the message");
        }
        window.removeEventListener("message", handleResponse);
      }
    }
    window.addEventListener("message", handleResponse);
    iframe.contentWindow.postMessage(customMessage, STORAGE_URL);
  }).catch((error) => {
    log("rlink.iframe-error", {
      version: __SCRIPT_VERSION__,
      error: error,
    });
  });

export const ensureIframeInitialized = async (): Promise<void> => {
  if (!iframe) {
    await initIframe();
  }
};
