import { AADetectionResults, SoftwallRecoveredType } from "../types";
import { setServerSideUserState } from "../utils/serverSideAttr";
import { LocalSettings } from "../../settings";
import { deleteRenderData, getLastRenderDate } from "./render";
import { deleteDismissedData } from "./dismiss";
import { deleteBounceData } from "./bounce";
import { deleteRedirectData } from "./redirect";
export const BT_AM_SOFTWALL_RECOVERED = "BT_AM_SOFTWALL_RECOVERED";

export const hasRecoveryData = () =>
  localStorage.getItem(BT_AM_SOFTWALL_RECOVERED) !== null;

export function hasRecovered(
  settings: LocalSettings,
  aaDetectionResults: AADetectionResults
): SoftwallRecoveredType | undefined {
  const latestRecoveryState = getRecoveryData();

  let currentRecoveryState: SoftwallRecoveredType | undefined;
  if (!aaDetectionResults.ab) {
    currentRecoveryState = "AB";
  } else if (
    aaDetectionResults.acceptable &&
    !settings.allow_render_to_aa_users
  ) {
    currentRecoveryState = "AA";
  }

  const isWithingRecoveryWindow = isWithinTheRecoveryWindow();

  // if user was recovered, but turn on AB again or disable the AA or
  // if MW has been rendered outside the recovery window and user still has AB enabled
  // allow MW to be rendered again and invalidate previous render data
  if (
    (latestRecoveryState || !isWithingRecoveryWindow) &&
    currentRecoveryState === undefined // if user no longer meets the rules to be identified as recovered - the currentRecoveryState value will be undefined
  ) {
    deleteRenderData();
  }

  if (!latestRecoveryState && !isWithingRecoveryWindow) return;

  // return actual user's recovery state
  return currentRecoveryState;
}

export function invalidateUserData() {
  deleteRecoveryData();
  deleteDismissedData();
  deleteRedirectData();
  deleteBounceData();
}

function getRecoveryData(): SoftwallRecoveredType {
  // if window.__bt_intrnl.recoveryUserAttr defined it means that
  // bt_tag or bt_am already tried to restore server side user attribution in current page view,
  // so the recovery type value could be getting from the window object;
  if (window.__bt_intrnl.recoveryUserAttr) {
    return window.__bt_intrnl.recoveryUserAttr.recoveryType;
  }
  return (
    (localStorage.getItem(BT_AM_SOFTWALL_RECOVERED) as SoftwallRecoveredType) ||
    undefined
  );
}

export const saveRecoveryData = (recoveredData: SoftwallRecoveredType) => {
  saveRecovery(recoveredData);
  setServerSideUserState(recoveredData);
};

export const saveRecovery = (recoveredData: SoftwallRecoveredType) =>
  localStorage.setItem(BT_AM_SOFTWALL_RECOVERED, recoveredData);

const deleteRecoveryData = () =>
  localStorage.removeItem(BT_AM_SOFTWALL_RECOVERED);

const MAX_DAYS_TO_RECOVER = 7;

/*
 * Check if the last render is inside the time limit
 * Otherwise it means that the user has recovered but not due to the softwall
 * */
const isWithinTheRecoveryWindow = () => {
  const lastRenderDate = getLastRenderDate();
  if (!lastRenderDate) return false;
  return differenceInDays(lastRenderDate) <= MAX_DAYS_TO_RECOVER;
};

const differenceInDays = (date1: Date, date2: Date = new Date()) =>
  Math.round(Math.abs(date1.getTime() - date2.getTime()) / (1000 * 3600 * 24));
