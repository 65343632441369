type Params = {
  bt_env?: "stage" | "prod";
  ignore_cache?: boolean;
  preview?: boolean;
};

export const QUERY_PARAMS = new Proxy(
  new URLSearchParams(window.location.search),
  {
    get: (searchParams, prop) => searchParams.get(prop as string),
  }
) as unknown as Params;

export const BT_ENV = QUERY_PARAMS.bt_env || "prod";
