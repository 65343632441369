import { Prebid } from "../types";
import { websiteID } from "../../settings";

const getKeyValuePairs = () => [
  { key: "bt_mwr", val: "true" },
  { key: "bt_wid", val: websiteID },
];

export const appendKVPToAdRequest = () => {
  appendKVPToAdPrebid();
  appendKVPToAdGoogleTag();
};

/* Prebid */

const appendKVPToAdPrebid = () => {
  const prebidInstances = getPrebidInstances();
  if (prebidInstances.length === 0) return false;

  prebidInstances.forEach((pbjs) => appendKVPToAdPrebidInstance(pbjs));
  return true;
};

const getPrebidInstances = () => {
  if (!window._pbjsGlobals) return [];
  return window._pbjsGlobals
    .map((pbjsKey) => window[pbjsKey])
    .filter(Boolean)
    .map(initializeBidderSettings);
};

const appendKVPToAdPrebidInstance = (pbjs: Prebid) => {
  const kvSet = getKeyValuePairs();
  kvSet.forEach((kvp) => {
    console.log("KV set to prebid: ", kvp);
    pbjs.bidderSettings.standard.adserverTargeting.push(
      getPrebidKeyValue(kvp.key, kvp.val)
    );
    console.log(pbjs.bidderSettings.standard.adserverTargeting);
  });
};

const initializeBidderSettings = (pbjs: Prebid) => {
  pbjs.bidderSettings = {
    ...pbjs.bidderSettings,
    standard: {
      ...pbjs.bidderSettings?.standard,
      adserverTargeting: [
        ...(pbjs.bidderSettings?.standard?.adserverTargeting || []),
      ],
    },
  };
  return pbjs;
};

const getPrebidKeyValue = (key: string, val: string) => ({
  key: key,
  val: () => val,
});

/* Google Tag */

const appendKVPToAdGoogleTag = () => {
  window.googletag?.cmd?.push(function () {
    const kvSet = getKeyValuePairs();
    kvSet.forEach((kvp) => {
      console.log("KV set to google tag: ", kvp);
      window.googletag.pubads().setTargeting(kvp.key, kvp.val);
      console.log(window.googletag.pubads());
    });
  });
};
