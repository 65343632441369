import { log } from "../utils/logs";

declare interface TCData {
  gdprApplies: boolean;
  eventStatus?: string;
  tcString: string;
  purpose?: {
    consents: {
      [key: number]: boolean;
    };
  };
}

async function getConsent(): Promise<TCData> {
  return new Promise((resolve, reject) => {
    if (window.__tcfapi && typeof window.__tcfapi === "function") {
      const callback = (tcData: TCData, success: boolean) => {
        if (success) {
          resolve(tcData);
        } else {
          reject("failed to get TCData");
        }
      };

      window.__tcfapi("addEventListener", 2, callback);
    }
    reject("tcfapi not found");
  });
}

// The eventStatus property of the TCData object shall be one of the following:
// - "tcloaded": CMP is loaded and is prepared to surface a TC String to any calling scripts on the page -
// this event status going to be returned on each next page views after user interacted with consent message.
// - "useractioncomplete": whenever a user has confirmed or re-confirmed their choices -
// this event status going to be returned in the same page view after user interacted with consent message
// - "cmpuishown": the UI is surfaced or re-surfaced
const consentShownInCurrentPV = (tcData: TCData) => {
  if (tcData?.eventStatus === "tcloaded") {
    return false;
  }
  if (
    tcData?.eventStatus === "useractioncomplete" ||
    tcData?.eventStatus === "cmpuishown"
  ) {
    return true;
  }

  // if we fall here it means that tcData missed or has unexpected event status, this actually should never happen
  // but if so, we're going to hande this as if TCF API failed
  return false;
};

const isConsent = (tcData: TCData) => {
  if (!tcData) {
    // if tcData is not find, we can't determine whether or not the user has consented
    return false;
  }
  if (tcData.gdprApplies === false) {
    // if gdpr is not applies there no need to check consents
    return true;
  }

  return !!(
    !allFalse(tcData.purpose?.consents) &&
    isRequiredConsentGiven(tcData.purpose)
  );
};

const allFalse = (obj = {}) => !Object.values(obj).some(Boolean);

const isRequiredConsentGiven = (purpose) => {
  const requirePurposes = [1, 2, 3, 4, 7, 9, 10];
  return requirePurposes.every((key) => purpose?.consents?.[key]);
};

// define if consent message appears.
export async function consentMessageShown() {
  return getConsent()
    .then((tcData: TCData) => {
      // Since this data required only for tracking in events for now save it to the window object.
      // This should be changed if we start using this data to prevent rendering
      window.__bt_intrnl.consentData = {
        tcDataDefined: !!tcData,
        gdprApplies: tcData?.gdprApplies,
        isConsent: isConsent(tcData),
      };

      if (!tcData || tcData.gdprApplies === false) {
        return false;
      }

      return consentShownInCurrentPV(tcData);
    })
    .catch((reason) => {
      window.__bt_intrnl.consentData = {
        tcDataDefined: false,
      };
      log(
        "rlink.getConsent",
        {
          version: __SCRIPT_VERSION__,
          pageURL: window.location.href,
          error: reason,
        },
        { severity: 3 }
      );
      return false;
    });
}
