function base64ToArrayBuffer(base64: string) {
  const len = base64.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = base64.charCodeAt(i);
  }
  return bytes.buffer;
}

/**
 * Key provided by the adblock team
 */
const publicKey = `MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEApyRTinEzv3cHH2bn6WSF
myqzDE/Ub8Xh/jhGWsSgHiJ6A/iM9GdlEE3WDl6ITw2ssuQ1xELT2xRpP9vR8OHR
YvyMfQtsjOsvMhXuR3X5Cf2nqSEo5ShihdE2W9kXxxWqs3yx4odnan6W65rfQWal
EJ8Y5viVEckj8x65+PTsDKfNtXMEvHqqrX9SaPC5rnZuWKkPuEJJna5ux/MEHuHQ
D6SFqhtPGKUYKf16pCFLD9oh6s6XcLST8GRZ2ySgdJKTMkHOHjjPkzXxYmTC1fYD
YO/THL+c9KvCpCWDd0yA6wEVABx2m7SYZzLkYVEze4SghGYQhWXLNwtj5tVzYGn0
+FoSNd9l3KJK/ULLd0HopuqYmz6TaK53x2rnjQ19epKSinRdPskmnz9RPzxojfli
SZgTQw47d6VV8J3K5HoLHtZ8vDD+KtYDNqHgLIG5jLsQLowetQAeRHqvo+KuIlzN
hIlNNOVq+Wlfe3nSsXlZGnKkqGC/4Bc39o82G4POR8qv98DRKm/6ihQAmWP7DUK2
HTumpVCsP4FRdaE2BT5BaAdXtoLmfHw6CsBSGFY0ZRmETsIIPhs8BIZg2EDd+Y/X
0Y8npvz5k4HxbTHS6pPO4hloPRwJ2MGZWwWtI2IppR749tONgOm4FI9tjUFLMLgU
ObHdD9PpRCG9MkGls9iSadkCAwEAAQ==`;

const algorithm = {
  name: "RSASSA-PKCS1-v1_5",
  modulusLength: 4096,
  publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
  hash: { name: "SHA-512" },
};

export async function verifySignature(data: {
  encodedData: string;
  signature: string;
}) {
  const pubKey = await crypto.subtle.importKey(
    "spki",
    base64ToArrayBuffer(atob(publicKey)),
    algorithm,
    false,
    ["verify"]
  );

  const signatureBuffer = base64ToArrayBuffer(atob(data.signature));
  const encodedDataBuffer = base64ToArrayBuffer(data.encodedData);

  return crypto.subtle.verify(
    algorithm,
    pubKey,
    signatureBuffer,
    encodedDataBuffer
  );
}
