import { log } from "../utils/logs";

const timingInfo = window.__bt_intrnl?.timings ?? {
  shouldLog: false,
};

const rlinkScriptLoadedTimestamp = Date.now();

const { shouldLog } = timingInfo;

export function logRlinkScriptLoaded() {
  const event = "timing.rlinkScriptLoaded";
  const meta = {
    timestamp: rlinkScriptLoadedTimestamp,
  };

  if (shouldLog) {
    log(event, meta);
  }
}
