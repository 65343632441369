import { IRLinkAPI } from "../types";

export class WaitModeAPI implements IRLinkAPI {
  constructor(private render: () => void) {}

  start() {}

  /**
   * Exposes the RLinkAPI by starting the ready event.
   */
  getAPIPayload() {
    return {
      render: () => {
        this.render();
      },
    };
  }
}
