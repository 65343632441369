import { apiDomain } from "../../settings";
import { log } from "../utils/logs";

/**
 * The key used to store the user's country in the local storage.
 */
const BT_USER_COUNTRY = "btUserCountry";

/**
 * A promise that resolves to the user's country or `undefined`.
 */
let getUserCountryPromise: Promise<string | undefined> | undefined;

/**
 * Retrieves the user's country.
 * If country already was defined, it retrieves the country from the session storage.
 * If the script has not country defined within current session, it retrieves the country from the API (only once per page load).
 * @returns A promise that resolves to the user's country or undefined if it cannot be determined.
 */
export const getUserCountry = async (): Promise<string | undefined> => {
  // Get country from session storage
  let country = sessionStorage.getItem(BT_USER_COUNTRY) || undefined;
  if (country) return country;

  // If the script was loaded by the recovery script, get the country from the API (only once per page load)
  if (!getUserCountryPromise) getUserCountryPromise = detectCountry();
  country = await getUserCountryPromise;

  // Save the country in the session storage
  country && setCountryOnSessionStorage(country);

  return country;
};

/**
 * Retrieves the user's country from the API.
 * @returns A promise that resolves to the user's country or undefined if it cannot be determined.
 */
const detectCountry = async () => {
  const settings = window.__bt_tag_am.settings;
  try {
    const response = await fetch(`https://${apiDomain}/country`);

    if (!response.ok)
      throw new Error(
        `Request failed. Status: ${
          response.status
        }. Raw: ${await response.text()}`
      );

    const data = await response.json();

    if (!data.country)
      throw new Error(`unexpected response: ${JSON.stringify(data)}`);

    return data.country as string;
  } catch (error) {
    log(
      "rlink.detectCountry",
      {
        version: __SCRIPT_VERSION__,
        settings: {
          countryList: settings.country_code_list,
          countryMode: settings.country_code_mode,
          renderToAA: settings.allow_render_to_aa_users,
          singleClick: settings.single_click_enabled,
        },
        aaResults: window.__bt_intrnl.aaDetectionResults,
        countryError: error.message,
        pageURL: window.location.href,
      },
      { severity: 4 }
    );
    return;
  }
};

const setCountryOnSessionStorage = (country: string) => {
  sessionStorage.setItem(BT_USER_COUNTRY, country);
};
