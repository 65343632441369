import { SoftwallRecoveredType } from "../types";
import { getLastRenderDate } from "../data";
import { apiDomain } from "../../settings";
import { BT_ENV, QUERY_PARAMS } from "./queryParams";

type UserAttrReq = {
  recoveryType?: SoftwallRecoveredType;
  renderDate: Date;
};

export function setServerSideUserState(recoveryType?: SoftwallRecoveredType) {
  const renderDate = getLastRenderDate() || new Date();
  callStateAPI({
    recoveryType,
    renderDate,
  });
}

function callStateAPI(request: UserAttrReq) {
  if (QUERY_PARAMS.ignore_cache) {
    // option to ignore recovery state cached at the back-end side
    return;
  }
  return fetch(`https://${apiDomain}/mw/state/recovered?bt_env=${BT_ENV}`, {
    method: "POST",
    body: JSON.stringify(request),
    headers: {
      "Content-Type": "application/json",
    },
    mode: "no-cors",
  });
}
