import { AADetectionResults, AdBlockerType, PageViewEventType } from "../types";
import { sendEvent } from "../utils";
import { QUERY_PARAMS } from "../utils/queryParams";

export const onPageView = (
  aaDetectionResults: AADetectionResults,
  detectedExtension: AdBlockerType,
  consentMessageShown: boolean
) => {
  if (QUERY_PARAMS.preview) {
    return Promise.resolve();
  }

  if (!window.__bt_intrnl.rlPVSent) {
    window.__bt_intrnl.rlPVSent = true;
    let event: PageViewEventType = "non_ab_pageview";
    if (aaDetectionResults.ab) {
      event = aaDetectionResults.acceptable ? "aa_pageview" : "ab_pageview";
    }

    return sendEvent({
      event,
      detectedExtension,
      consentMessageShown,
    });
  }
};
