const SOFTWALL_RENDER_COUNT_KEY = "BT_AM_SOFTWALL_RENDER_COUNT";

let hasIncremented = false;

export const incrementRenderCount = () => {
  if (hasIncremented) return;
  const count = getRenderCountData();
  saveRenderCountData(count + 1);
  hasIncremented = true;
};

export const getRenderCountData = () => {
  const lastRenderDateStr = localStorage.getItem(SOFTWALL_RENDER_COUNT_KEY);
  return lastRenderDateStr ? Number(lastRenderDateStr) : 0;
};

const saveRenderCountData = (count: number) => {
  localStorage.setItem(SOFTWALL_RENDER_COUNT_KEY, count.toString());
};
