import { EventHandler, IRLinkAPI } from "./types";

/**
 * Represents the RLinkAPI class.
 */
export class RLinkAPIEventManager {
  /**
   * The event names to dispatch.
   */
  private out = {
    /*
     * The event to dispatch when the RLink API is ready.
     */
    API_READY_EVENT: "bt-rlink-api-ready",
  };

  private interval: NodeJS.Timer;

  /**
   * Creates an instance of RLinkAPI.
   * @param render - The render function to be called to render the RLink message.
   * @param eventHandler - The event handler to use for listening and dispatching events.
   * @param apiReadyEventInterval - The interval (in milliseconds) at which the API_READY_EVENT is dispatched. Default is 200ms.
   */
  constructor(
    private api: IRLinkAPI,
    private eventHandler: EventHandler,
    private apiReadyEventInterval
  ) {}

  /**
   * Exposes the RLinkAPI by starting the ready event.
   */
  async start() {
    this.api.start();
    this.startDispachingReadyEvent();
  }

  /**
   * Sends the API_READY_EVENT to indicate that the RLink API is ready.
   */
  private sendReadyEvent() {
    const apiPayload = this.api.getAPIPayload();
    this.eventHandler.dispatchEvent(
      new CustomEvent(this.out.API_READY_EVENT, {
        detail: {
          api: () => {
            this.stopDispachingReadyEvent();
            return apiPayload;
          },
        },
      })
    );
  }

  /**
   * Starts the ready event by sending the API_READY_EVENT at the specified interval
   * This is done to avoid race conditions where the API_READY_EVENT is dispatched before the event listener is added
   */
  private startDispachingReadyEvent() {
    this.sendReadyEvent();
    this.interval = setInterval(() => {
      this.sendReadyEvent();
    }, this.apiReadyEventInterval);
  }

  /**
   * Stops the ready event by clearing the interval.
   */
  private stopDispachingReadyEvent() {
    clearInterval(this.interval);
  }
}
