import { savePremiumDetection } from "../data";
import { AdBlockerType } from "../types";
import { sendEvent } from "../utils";

export const onPremiumUserDetected = (detectedExtension: AdBlockerType) => {
  savePremiumDetection();
  sendEvent({
    event: "flattr",
    detectedExtension,
  });
};
