import { LocalSettings } from "../../settings";
import { hasDismissedData, saveDismissData } from "../data";
import { AdBlockerType, DismissData } from "../types";
import { sendEvent } from "../utils";

interface DismissHandler {
  onDismiss: (dismissData: DismissData) => void;
}

export class SessionPersistentDismissHandler implements DismissHandler {
  constructor(private detectedExtension: AdBlockerType) {}
  onDismiss = async (dismissData: DismissData) => {
    if (!(await hasDismissedData())) {
      await saveDismissData(dismissData);
      sendEvent({
        ...dismissData,
        event: "dismiss",
        detectedExtension: this.detectedExtension,
      });
    }
  };
}

export class NonPersistentDismissHandler implements DismissHandler {
  constructor(private detectedExtension: AdBlockerType) {}
  onDismiss = (dismissData: DismissData) => {
    sendEvent({
      ...dismissData,
      event: "dismiss",
      detectedExtension: this.detectedExtension,
    });
  };
}

export const getDismissHandler = (
  detectedExtension: AdBlockerType,
  settings: LocalSettings
) => {
  if (settings.render_mode === "api" && settings.api_mode === "wait") {
    return new NonPersistentDismissHandler(detectedExtension);
  }
  return new SessionPersistentDismissHandler(detectedExtension);
};
