import { getSettings, loadRecoveryScript, LocalSettings } from "./settings";
import {
  getDismissData,
  getRenderData,
  hasRecovered,
  consentMessageShown,
  logRlinkScriptLoaded,
} from "./softwall/data";
import {
  getDismissHandler,
  onPageView,
  onPremiumUserDetected,
  onRecover,
  onBounce,
} from "./softwall/handlers";
import { RLinkAPI } from "./api";
import { BeforeUnloadOption, DismissData } from "./softwall/types";
import {
  adsAllowed,
  detectAdBlocker,
  detectPremiumABUser,
  getAADetectionResult,
  renderAllowed,
} from "./softwall/utils";
import { log } from "./softwall/utils/logs";

(async () => {
  if (window.__bt_rlink_already_invoked) return;
  // a safety check to avoid running rlink script running twice
  // it could happen was injected into the page along with bt_tag in inject from tag mode
  // or a bug with an infinite script loading cycle

  logRlinkScriptLoaded();
  window.__bt_rlink_already_invoked = true;

  let settings: LocalSettings;
  try {
    settings = await getSettings();
  } catch (error) {
    log(
      "rlink.getSettingsError",
      {
        version: __SCRIPT_VERSION__,
        aaResults: window.__bt_intrnl.aaDetectionResults,
        // I'm not sure if we need here settings
      },
      { severity: 4 }
    );
    settings = {} as LocalSettings;
  }

  const { allow_render_to_aa_users, hard_message_wall_mode_enabled } = settings;
  const [
    aaDetectionResults,
    detectedExtension,
    hasRendered,
    isPremiumAbUser,
    isConsentMessageShown,
  ] = await Promise.all([
    getAADetectionResult(),
    detectAdBlocker(),
    getRenderData(),
    detectPremiumABUser(settings),
    consentMessageShown(),
  ]).catch(() => {
    log(
      "rlink.getDetectionError",
      {
        version: __SCRIPT_VERSION__,
        aaResults: window.__bt_intrnl.aaDetectionResults,
        // I'm not sure if we need here settings
      },
      { severity: 4 }
    );

    return [];
  });

  // detectedExtension contains "abp" by default, even if there is no adblocker enabled.
  const handlePageView = async () => {
    try {
      await onPageView(
        aaDetectionResults,
        detectedExtension,
        isConsentMessageShown
      );
    } catch (error) {
      log(
        "rlink.onPageViewError",
        {
          version: __SCRIPT_VERSION__,
          aaResults: window.__bt_intrnl.aaDetectionResults,
          // I'm not sure if we need here settings
        },
        { severity: 4 }
      );
    }
  };

  handlePageView();

  const beforeUnloadHandler = () => {
    onBounce(detectedExtension, settings);
  };

  const beforeUnload = (opt: BeforeUnloadOption) => {
    if (opt === "subscribe") {
      window.addEventListener("beforeunload", beforeUnloadHandler);
    } else {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    }
  };

  const dismissHandler = getDismissHandler(detectedExtension, settings);

  // RL should not be rendered if ads already allowed (AB disabled or AA enabled and rendering not allowed for AA users)
  const shouldNotBeRendered =
    adsAllowed(aaDetectionResults, allow_render_to_aa_users) ||
    isConsentMessageShown;

  // Stop RL for first run if it should not be rendered
  if (!hasRendered && shouldNotBeRendered) {
    return;
  }

  const swRecovered = hasRecovered(settings, aaDetectionResults);

  // recovery event
  if (swRecovered) {
    onRecover(swRecovered, detectedExtension);
    return;
  }

  // Always load recover script if we have SoftWall
  if (!hard_message_wall_mode_enabled) {
    loadRecoveryScript(settings);
  }

  const onDismiss = (dismissData: DismissData) => {
    beforeUnload("unsubscribe"); // if user dismiss the wall stop listen to beforeunload
    dismissHandler.onDismiss(dismissData);
  };

  // dismiss event
  const dismissData = await getDismissData();
  if (dismissData && !hard_message_wall_mode_enabled) {
    onDismiss(dismissData);
    return;
  }

  // handle premium ABP users
  if (isPremiumAbUser) {
    onPremiumUserDetected(detectedExtension);
    return;
  }

  // render RL if the rules for rendering are met
  if (!shouldNotBeRendered && (await renderAllowed(settings))) {
    import("./App").then(({ render }) => {
      const renderProps = {
        settings,
        onDismiss,
        beforeUnload,
        detectedExtension,
        aaDetectionResults,
      };
      const callRender = () => render(renderProps);
      if (settings.render_mode === "api") {
        new RLinkAPI(callRender, {
          mode: settings.api_mode,
        }).expose();
      } else {
        callRender();
      }
    });
  } else {
    loadRecoveryScript(settings);
  }
})();
