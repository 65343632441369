import { DismissData } from "../types";
import {
  ensureIframeInitialized,
  postMessageToIframe,
} from "../utils/internalStorageIframe";

const SOFTWALL_DISMISSED_KEY = "BT_AM_SOFTWALL_DISMISSED";

export const getDismissData = async (): Promise<DismissData | undefined> => {
  const dismissDataStr =
    sessionStorage.getItem(SOFTWALL_DISMISSED_KEY) ||
    (await getDismissDataFromIframe());

  if (dismissDataStr) {
    try {
      return JSON.parse(dismissDataStr);
    } catch (e) {
      console.error(e);
    }
  }
  return undefined;
};

const getDismissDataFromIframe = async (): Promise<string | undefined> => {
  await ensureIframeInitialized();
  const result = await postMessageToIframe({
    action: "get-session-data",
    key: SOFTWALL_DISMISSED_KEY,
  });
  return result as string | undefined;
};

export const hasDismissedData = async (): Promise<boolean> => {
  const dismissDataStr = sessionStorage.getItem(SOFTWALL_DISMISSED_KEY);
  if (dismissDataStr !== null) {
    return true;
  }
  const iframeDismissDataStr = await getDismissDataFromIframe();
  return iframeDismissDataStr !== null;
};

export const saveDismissData = async (
  dismissData: DismissData
): Promise<void> => {
  const dismissDataStr = JSON.stringify(dismissData);
  sessionStorage.setItem(SOFTWALL_DISMISSED_KEY, dismissDataStr);
  await ensureIframeInitialized();
  await postMessageToIframe({
    action: "set-session-data",
    key: SOFTWALL_DISMISSED_KEY,
    value: dismissDataStr,
  });
};

export const deleteDismissedData = async (): Promise<void> => {
  sessionStorage.removeItem(SOFTWALL_DISMISSED_KEY);
  await ensureIframeInitialized();
  await postMessageToIframe({
    action: "remove-session-data",
    key: SOFTWALL_DISMISSED_KEY,
  });
};
